import React from 'react';
import { css } from '@emotion/react';
import { Button } from 'antd';

const AXALoginButton = ({ onClick }: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Button
      block
      css={css`
        border-radius: 0;
        height: 2.75em;
        border: 0;
        color: #fff;
        font-size: 1rem;
        background-color: #b03c1d;

        :hover,
        :focus,
        :active {
          background-color: #9f361a;
          color: #fff;
        }
      `}
      data-testid="axa-login-button"
      onClick={onClick}
    >
      AXA Health Log In
    </Button>
  );
};

export default AXALoginButton;
